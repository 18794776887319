import React from 'react';
import { get } from 'lodash';

import CreatableSelect from 'react-select/creatable';
import {
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';

const components = { DropdownIndicator: null };
const createOption = (text: string) => ({ label: text, value: text });
const flat = (v: ISelectOption) => v.label;

interface ITagInputProps extends ISharedSelectProps {
  value: string[];
  placeholder?: string;
  onValidation?: (value: string, list: string[]) => boolean;
}

interface ITagInputState {
  inputValue: string;
  value: ISelectOption[];
}

export default class TagInput extends React.Component<ITagInputProps, ITagInputState> {
  static defaultProps = {
    placeholder: '',
  };

  constructor(props: ITagInputProps) {
    super(props);
    this.state = {
      inputValue: '',
      value: props.value.map(v => createOption(v)),
    };
  }

  handleChange = (value: any, actionMeta: any) => {
    this.setState(
      { value },
      () => this.props.onChange(this.state.value.map(flat)),
    );
  };

  handleBlur = (event: React.SyntheticEvent<HTMLElement>) => {
    const inputValue = get(event, 'currentTarget.value', null);
    if (inputValue) {
      const { onValidation } = this.props;
      const { value } = this.state;
      if (value.includes(inputValue)) {
        return;
      }
      if (onValidation && onValidation(inputValue, this.state.value.map(flat))) {
        this.setState(
          {
            value: [
              ...value,
              { value: inputValue, label: inputValue },
            ],
          },
          () => this.props.onBlur(this.state.value.map(flat)),
        );
      }
    }
  };

  handleInputChange = (inputValue: string) => {
    this.setState({ inputValue });
  };

  handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    const { inputValue, value } = this.state;
    if (!inputValue) return;
    if (event.key === 'Enter' || event.key === 'Tab') {
      if (this.props.onValidation ? this.props.onValidation(inputValue, value.map(flat)) : true) {
        this.setState(
          {
            inputValue: '',
            value: [...value, createOption(inputValue)],
          },
          () => this.props.onChange(this.state.value.map(flat)),
        );
      }
      event.preventDefault();
    }
  };

  render() {
    const { placeholder } = this.props;
    const {
      inputValue,
      value,
    } = this.state;
    return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={this.handleChange}
        onBlur={this.handleBlur}
        onInputChange={this.handleInputChange}
        onKeyDown={this.handleKeyDown}
        placeholder={placeholder}
        value={value}
      />
    );
  }
}
