import React from 'react';
import { Input } from 'reactstrap';
import i18n from 'features/intl/i18n';

import { EventSourceEnum } from 'features/events/types';
import { ISelectOption } from 'features/types';

const types = [
  { value: null, label: i18n.t('events:source_default') },
  { value: EventSourceEnum.vk, label: i18n.t('events:vk') },
  { value: EventSourceEnum.webinar, label: i18n.t('events:webinar') },
  { value: EventSourceEnum.self_streaming, label: i18n.t('events:self_streaming') },
  { value: EventSourceEnum.plugnmeet, label: i18n.t('events:plugnmeet') },
];

interface ISourceSelectProps {
  id?: string;
  name?: string;
  value: string | number;
  emptyOption?: ISelectOption;
  onChange: any;
  onBlur?: any;
}

const SourceSelect = (props: ISourceSelectProps) => {
  const {
    id,
    value,
    name,
    emptyOption,
    onChange,
    onBlur,
  } = props;
  const options = types
    .map(type => (
      <option key={type.label} value={type.value}>
        {type.label}
      </option>
    ));
  return (
    <Input
      type="select"
      id={id}
      name={name}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
    >
      {emptyOption ? <option value={emptyOption.value}>{emptyOption.label}</option> : null}
      {options}
    </Input>
  );
};

export default SourceSelect;
