import React from 'react';
import { Formik, FormikActions, FormikProps } from 'formik';
import { WithTranslation, withTranslation } from 'react-i18next';
import { ISet } from 'features/sets/types';
import BaseSetForm from './BaseSetForm';
import * as Yup from 'yup';
import field from 'lib/field';
import filterChangedValues from 'lib/filterChangedValues';
import { crudMutate } from 'features/common/helpers';
import { makeSetUrl } from 'features/sets/lib/url';
import editSetMutation from 'features/sets/queries/editSet.gql';
import createSetMutation from 'features/sets/queries/createSet.gql';
import { imageFileValidator } from 'lib/validators';

interface ISetFormProps extends WithTranslation {
  set?: ISet;
  isCreateForm?: boolean;
}

class SetForm extends React.Component<ISetFormProps> {
  getScheme() {
    const { t, set, isCreateForm } = this.props;
    return Yup.object().shape({
      name: t('name'),
      description: t('description'),
      items: t('items'),
      image: imageFileValidator({
        field: t('image'),
        required: !!isCreateForm,
      }),
    });
  }

  buildFormData() {
    const { set } = this.props;

    return {
      name: field(set, 'name', null),
      description: field(set, 'description', null),
      image: field(set, 'images[0].path', null),
      offers: set && set.offers ? set.offers.map(offer => parseInt(offer.id, 10)) : [],
      creators: set && set.creators ? set.creators.map(creator => parseInt(creator.id, 10)) : [],
      relatedItems: set && set.relatedItems ? set.relatedItems.map(relatedItem => parseInt(relatedItem.id, 10)) : [],
      items: set && set.items
        ? set.items
          .map(item => ({ ...item, id: parseInt(item.id, 10) }))
          .sort((a, b) => a.sort - b.sort)
        : [],
    };
  }

  getChangedValue(values: ISet) {
    const formData = this.buildFormData();
    return filterChangedValues(formData, values);
  }

  onSubmit = (
    values: ISet,
    formActions: FormikActions<ISet>,
  ) => {
    let set = { ...values };
    let id;
    if (this.props.set) {
      set = this.getChangedValue(values);
      id = this.props.set.id;
    }
    if (set.items) {
      set.items = set.items.map(item => ({ id: item.id, sort: item.sort, description: item.description || '' }));
    }

    crudMutate({
      id,
      formActions,
      variables: id ? { id, set } : { set },
      mutation: id ? editSetMutation : createSetMutation,
      redirect: makeSetUrl(),
      check: !!Object.keys(set).length,
    });
  }

  render() {
    const { set } = this.props;
    return (
      <Formik
        onSubmit={this.onSubmit}
        initialValues={this.buildFormData()}
        validationSchema={this.getScheme()}
      >
        {(props: FormikProps<ISet>) => <BaseSetForm {...props} set={set} />}
      </Formik>
    );
  }
} export default withTranslation('sets')(SetForm);
