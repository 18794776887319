import React from 'react';
import FormRow from 'features/ui/FormRow';
import { FormikProps } from 'formik';
import { IFormValues } from 'features/events/types';
import { WithTranslation } from 'react-i18next';
import { IItem } from 'features/items/types';
import { Button } from 'reactstrap';
import { client, gql } from 'features/graphql';
import { stubTrue, toInteger } from 'lodash';

interface IPlugnmeetFormsProps extends FormikProps<IFormValues>, WithTranslation {
  item?: IItem;
}

interface IState {
  links: Array<{ link: string; user: string }>;
  records: Array<{
    id: number;
    size: number;
    url: string;
    created_at: string;
  }>;
  emptyRecords: boolean;
}

class PlugnmeetForms extends React.Component<IPlugnmeetFormsProps, IState> {
  constructor(props: IPlugnmeetFormsProps) {
    super(props);
    this.state = {
      links: [],
      records: [],
      emptyRecords: false,
    };
  }

  handleCallLinks = () => {
    const { values, item } = this.props;
    const users = [...(values.chat.moderators || []), ...(values.chat.speakers || [])];
    if (users.length) {
      users.forEach((user) => {
        client.query({
          query: gql`
            query plugnmeetQuery($item_id: Int, $user_id: Int) {
              PlugnmeetQuery(item_id: $item_id, user_id: $user_id) {
                items {
                  joinUrl
                }
              }
            }
          `,
          variables: {
            item_id: toInteger(item.id),
            user_id: toInteger(user),
          },
        }).then((res) => {
          const link = res.data.PlugnmeetQuery.items[0].joinUrl;

          this.setState({ links: [...this.state.links, { link, user }] });
        }).catch(err => global.console.error(`[Error]: ${err}`));
      });
    }
  }

  handleCallEventRecords = () => {
    const { item } = this.props;
    if (item) {
      client.query({
        query: gql`
          query EventRecordingQuery($item_id: Int) {
            EventRecordingQuery(item_id: $item_id) {
              items {
                id
                size
                url
                created_at
              }
            }
          }
        `,
        variables: {
          item_id: toInteger(item.id),
        },
      }).then((res) => {
        const { data } = res;
        const { items } = data.EventRecordingQuery;
        if (!items.length) {
          this.setState({ emptyRecords: true });
        }
        this.setState({ records: items });
      }).catch(err => global.console.error(`[Error]: ${err}`));
    }
  }

  render() {
    const { t } = this.props;
    const {
      links,
      records,
      emptyRecords,
    } = this.state;

    return (
      <React.Fragment>
        <FormRow
          id="links_button"
          key="links_button"
          label={t('links_button')}
          shouldUpdate={stubTrue}
        >
          {
            links.length
              ? (
                <div>
                  {links.map(link => (
                    <p key={link.user}>
                      {link.user}:&nbsp;
                      <a
                        href={link.link}
                        target="_blank"
                      >
                        {link.link}
                      </a>
                    </p>
                  ))}
                </div>
              ) : null
          }
          <Button onClick={this.handleCallLinks}>
            {t('get_links')}
          </Button>
        </FormRow>
        <FormRow
          id="event_records"
          key="event_records"
          label={t('event_records')}
          shouldUpdate={stubTrue}
        >
          {records.length
            ? (
              <div>
                {records.map(record => (
                  <p key={record.id}>{record.url}</p>
                ))}
              </div>
            ) : null}
          {emptyRecords ? <p>{t('no_records')}</p> : null}
          <Button onClick={this.handleCallEventRecords}>
            {t('get_records')}
          </Button>
        </FormRow>
      </React.Fragment>
    );
  }
}

export default PlugnmeetForms;
