import React from 'react';
import { FormikProps } from 'formik';
import { withTranslation, WithTranslation } from 'react-i18next';
import { ISet } from 'features/sets/types';
import FormFrame from 'features/ui/FormFrame';
import { makeSetUrl } from 'features/sets/lib/url';
import FormRow from 'features/ui/FormRow';
import Input from 'features/ui/Input';
import ItemsSelect from 'features/items/lib/ItemsSelect';
import ImageUploader from 'features/ui/ImageUploader';
import OffersSelectWithButtons from 'features/offers/lib/OffersSelectWithButtons';
import DragNDropComponent from 'features/ui/DragNDropComponent';
import styles from 'features/courses/lib/Stages/style.module.scss';
import CreatorsSelect from 'features/creators/lib/CreatorsSelect';
import i18n from 'i18next';

interface IBaseSetFormProps extends FormikProps<ISet>, WithTranslation {
  set?: ISet;
}

const SelectedOption = (props: any) => {
  const { id, name, sort, description } = props.childItems;
  const { onRemove, onChange } = props;
  return (
    <div>
      <div className={styles['selectable-list-item']}>

        <div>{sort} | {id} | {name}</div>
        <div className={`close ${styles['close-container']}`} onClick={() => onRemove(id)}>
          <i className="icon-close"/>
        </div>
      </div>
      <div className={styles['selectable-list-item']}>
        <Input
          id="description"
          type="textarea"
          value={description}
          onChange={(e) => onChange(e.target.value, id)}
        />
      </div>
    </div>
  );
};

class BaseSetForm extends React.Component<IBaseSetFormProps> {
  onItemChange = (values, current: any) => {
    if (current && current.option) {
      this.props.setFieldValue('items', [...this.props.values.items, {
        id: current.option.value,
        name: current.option.label,
        sort: this.props.values.items.length + 1,
      }]);
    }
  }

  onChangeImage = (e: any) => {
    if (e.target.validity.valid) {
      this.props.setFieldValue('image', e.target.files![0]);
    }
  };

  onChangeOffers = (ids: ID[]) => {
    this.props.setFieldValue('offers', ids.slice());
  };

  onChangeCreators = (ids: ID[]) => {
    this.props.setFieldValue('creators', ids.slice());
  };

  onChangeEntityIds = (field: string) => (ids: number[]) => {
    this.props.setFieldValue(field, ids);
  };

  onDragEnd = ({ destination, draggableId }) => {
    const { setFieldValue } = this.props;
    const { items } = this.props.values;
    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(
      items.findIndex((item) => parseInt(item.id, 10) === parseInt(draggableId, 10)),
      1,
    );
    newItems.splice(destination.index, 0, reorderedItem);
    setFieldValue('items', newItems.map((item, index) => ({ ...item, sort: index + 1 })));
  }

  onRemove = (id) => {
    const { setFieldValue } = this.props;
    const { items } = this.props.values;
    const newItems = items.filter((item) => item.id !== id);
    setFieldValue('items', newItems.map((item, index) => ({ ...item, sort: index + 1 })));
  };

  onChangeDescription = (description: string, id: number) => {
    const { setFieldValue, values } = this.props;
    const { items } = values;
    const updatedItems = items.map(item => item.id === id ? { ...item, description } : item);

    setFieldValue('items', updatedItems);
  }

  onBlur = (field: string) => {
    this.props.setFieldTouched(field, true);
  };

  render() {
    const {
      handleBlur,
      handleChange,
      t,
      values,
      set,
    } = this.props;
    const { items } = values;
    return (
      <FormFrame
        id={set ? set.id : undefined}
        cancelLink={makeSetUrl()}
        {...this.props}
      >
        <FormRow label={t('name')} id="name" required>
          <Input
            type="text"
            id="name"
            name="name"
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>
        <FormRow label={t('description')} id="description" required>
          <Input
            type="textarea"
            id="description"
            name="description"
            value={values.description}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRow>
        <FormRow label={t('items')} id="items">
          <DragNDropComponent items={items} onDragEnd={this.onDragEnd} childPropName="childItems">
            <SelectedOption onRemove={this.onRemove} onChange={this.onChangeDescription} />
          </DragNDropComponent>
          <ItemsSelect
            id="items"
            name="items"
            isClearable
            onChange={this.onItemChange}
            onBlur={this.onItemChange}
          />
        </FormRow>
        <hr />
        <FormRow
          label={t('image')}
          id="image"
          text={t('forms:expected_size', {
            width: 1440,
            height: 768,
          })}
          required
        >
          <ImageUploader
            id="image"
            name="image"
            value={values.image}
            onChange={this.onChangeImage}
            onBlur={handleBlur}
            imageWidth={1440 / 4/* from original size to preview */}
            imageHeight={768 / 4}
          />
        </FormRow>
        <FormRow key="relatedItems" label={t('relatedItems')} id="relatedItems">
          <ItemsSelect
            id="relatedItems"
            name="relatedItems"
            value={values.relatedItems}
            isClearable={true}
            isMulti
            onChange={this.onChangeEntityIds('relatedItems')}
            onBlur={this.onBlur.bind(this, 'relatedItems')}
          />
        </FormRow>
        <FormRow
          key="creators"
          label={t('creators')}
          id="creators"
        >
          <CreatorsSelect
            isMulti
            type="author"
            id="creators"
            name="creators"
            value={values.creators}
            onChange={this.onChangeCreators}
            onBlur={handleBlur}
          />
        </FormRow>
        <FormRow
          label={t('offers')}
          id="offers"
        >
          <OffersSelectWithButtons
            id="offers"
            name="offers"
            isMulti={true}
            value={values.offers}
            onChange={this.onChangeOffers}
            onBlur={handleBlur}
          />
        </FormRow>
      </FormFrame>
    );
  }
}

export default (withTranslation('sets')(BaseSetForm));
