import React, { MouseEventHandler } from 'react';
import { noop } from 'lodash';
import Select, { components } from 'react-select';
import AsyncSelect from 'react-select/async';
import CreatableSelect from 'react-select/async-creatable';
import {
  ISelectOption,
  ISelectOptionsByValue,
  ISharedSelectProps,
} from 'features/types';
import { SortableContainer, SortableElement, SortableHandle, SortEndHandler } from 'react-sortable-hoc';
import { itemTypeTranslator } from 'features/ui/ReactSelect/helpers';

export interface IReactSelectProps extends ISharedSelectProps {
  options: ISelectOption[];
  isClearable?: boolean;
  isMulti: boolean;
  shrinkHeight?: number;
  async?: boolean;
  creatable?: boolean;
  minSearchLength?: number;
  loadOptions?: any;
  noOptionsMessage?: () => string;
  onCreateOption?: any;
  formatCreateLabel?: any;
  createOptionPosition?: 'first' | 'last';
  components?: any;
  styles?: any;
  onPaste?: boolean | ((e: any) => void);
  responsive?: boolean;
}

interface IReactSelectState {
  optionsByValue: ISelectOptionsByValue;
  showOptions: boolean;
  selectedOptions: ISelectOption[];
  creatableOptions: ISelectOption[];
}

const SortableMultiValue = SortableElement((props: any) => {
  const onMouseDown: MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { ...props.innerProps, onMouseDown };
  const {
    label,
    value,
    type,
  } = props.data;

  return (
    <components.MultiValue {...props} innerProps={innerProps}>
      <b>{value}</b> | {label} {type ? `| ${itemTypeTranslator(type)}` : null}
    </components.MultiValue>
  );
});

const SortableMultiValueLabel = SortableHandle((props) => {
  const {
    label,
    value,
    type,
  } = props.data;

  return (
    <components.MultiValueLabel {...props}>
      <span style={{ overflow: 'hidden', textOverflow: 'ellipsis', width: '90%' }}><b>{value}</b> | {label}</span>
      <span style={{ width: '10%' }}>{type ? `| ${itemTypeTranslator(type)}` : null}</span>
    </components.MultiValueLabel>
  );
});

const SortableSelect = SortableContainer(AsyncSelect);

class ReactSelect extends React.PureComponent<IReactSelectProps, IReactSelectState> {
  static getDerivedStateFromProps(props: IReactSelectProps) {
    const values = Array.isArray(props.value) ? props.value : [props.value];
    const selectedOptions = props.options.filter((option: ISelectOption) => values.map(String).includes(String(option.value)));
    return { selectedOptions };
  }

  searchTimeout?: number;

  constructor(props: IReactSelectProps) {
    super(props);

    this.state = {
      optionsByValue: {},
      showOptions: !props.minSearchLength,
      selectedOptions: [],
      creatableOptions: [],
    };
  }

  onPaste = (e: any) => {
    const { onChange } = this.props;
    const { selectedOptions } = this.state;
    e.preventDefault();
    e.stopPropagation();
    const value = e.clipboardData.getData('text/plain');

    const options: string[] = value
      .replace(/[^\d\wа-яА-ЯёЁ,;\r\n ]/g, '')
      .split(/,|;|\n/g)
      .map((option: string) => option.replace(/[^\d\wа-яА-ЯёЁ]/g, ''))
      .filter((option: string) => option);

    if (options.length) {
      const creatableOptions = options.map(option => ({ value: option, label: option }));
      this.setState({
        creatableOptions,
      });
      onChange({
        creatableOptions,
        data: selectedOptions.concat(creatableOptions),
      });
    }
  };

  // todo: ValueType from react-select is not exported
  // @see https://github.com/JedWatson/react-select/issues/2902
  onChange = (data: any, action: any) => {
    const {
      isMulti,
      creatable,
    } = this.props;
    const { creatableOptions } = this.state;

    if (!creatable) {
      const value = isMulti ? (data ? data.map((cur: ISelectOption) => cur.value) : []) : data && data.value;
      const other = isMulti ? (data ? data.map((cur: ISelectOption) => cur) : []) : data && data;
      this.props.onChange(value, action, other);
      return;
    }

    const newCreatableOptions = creatableOptions.filter(
      (option: ISelectOption) => data.some((cur: ISelectOption) => cur.value === option.value),
    );

    this.setState({
      creatableOptions: newCreatableOptions,
    });

    this.props.onChange(
      {
        data,
        creatableOptions: newCreatableOptions,
      },
      action,
    );
  };

  onInputChange = (str: string) => {
    this.setState({
      showOptions: str.length >= this.props.minSearchLength!,
    });
  };

  handleBlur = () => {
    if (this.props.onBlur) {
      this.props.onBlur();
    }
  };

  loadOptions = (value: string) => {
    const { loadOptions, minSearchLength } = this.props;

    if (value.length >= 2 || (minSearchLength && value.length >= minSearchLength)) {
      if (this.searchTimeout) {
        window.clearTimeout(this.searchTimeout);
      }
      return new Promise((resolve) => {
        this.searchTimeout = window.setTimeout(
          () => {
            loadOptions(value).then((options: ISelectOption[]) => resolve(options));
          },
          500,
        );
      });
    }

    return Promise.resolve([]);
  };

  noOptionsMessage = () => 'Ничего не найдено';

  isValidNewOption = (inputValue: string, selectValue: any, selectOptions: ISelectOption[]) => {
    return inputValue.length > 1 && !selectOptions.some(option => option.label === inputValue);
  };

  onCreateOption = (inputValue: string) => {
    const {
      creatableOptions,
      selectedOptions,
    } = this.state;

    const newOption = {
      label: inputValue,
      value: inputValue,
    };

    const newCreatableOptions = [...creatableOptions, newOption];

    this.setState({
      creatableOptions: newCreatableOptions,
    });

    this.props.onCreateOption({
      selectedOptions,
      option: newOption,
      creatableOptions: newCreatableOptions,
    });
  };

  renderCreatable = () => {
    const {
      id,
      name,
      options,
      isMulti,
      loadOptions,
      noOptionsMessage,
      formatCreateLabel,
      createOptionPosition,
      onPaste,
    } = this.props;

    const {
      selectedOptions,
      creatableOptions,
    } = this.state;

    const select = (
      <CreatableSelect
        inputId={id}
        name={name}
        // options={options}
        // cacheOptions
        onCreateOption={this.onCreateOption}
        createOptionPosition={createOptionPosition}
        defaultOptions={[...options, ...creatableOptions]}
        loadOptions={loadOptions ? this.loadOptions : noop}
        isMulti={isMulti}
        value={[...selectedOptions, ...creatableOptions]}
        isValidNewOption={this.isValidNewOption}
        onChange={this.onChange}
        onBlur={this.handleBlur}
        noOptionsMessage={noOptionsMessage || this.noOptionsMessage}
        placeholder={'Начните вводить для поиска или создания...'}
        formatCreateLabel={formatCreateLabel}
      />
    );

    return onPaste ? (<div onPaste={onPaste === true ? this.onPaste : onPaste}>{select}</div>) : select;
  };

  renderAsync = () => {
    const {
      id,
      name,
      options,
      isMulti,
      loadOptions,
      noOptionsMessage,
      components,
      isClearable,
      onPaste,
    } = this.props;

    const {
      selectedOptions,
    } = this.state;

    const getClipboardData = (e: any) => {
      const clipboardData = e.clipboardData || window.clipboardData;
      return clipboardData.getData('Text');
    };

    const select = (
      <AsyncSelect
        inputId={id}
        name={name}
        isClearable={isClearable}
        cacheOptions
        defaultOptions={options}
        loadOptions={loadOptions ? this.loadOptions : noop}
        isMulti={isMulti}
        value={selectedOptions}
        onChange={this.onChange}
        onBlur={this.handleBlur}
        noOptionsMessage={noOptionsMessage || this.noOptionsMessage}
        placeholder={'Начните вводить (минимально 3 символа)...'}
        components={components}
      />
    );

    return onPaste ? (<div onPaste={(e) => onPaste(getClipboardData(e))}>{select}</div>) : select;
  };

  renderSelect() {
    const {
      id,
      name,
      options,
      isMulti,
      minSearchLength,
      isClearable,
      components,
      styles,
      shrinkHeight,
    } = this.props;

    const {
      showOptions,
      selectedOptions,
    } = this.state;

    const selectStyles = {
      menu: (s: any) => ({
        ...s,
        maxHeight: shrinkHeight ? shrinkHeight : 500,
        overflowY: 'scroll',
      }),
      singleValue: (s: any) => ({
        ...s,
        ...(styles && styles.singleValue && styles.singleValue(s)),
      }),
      valueContainer: (s: any) => ({
        ...s,
        maxHeight: 200,
        overflowY: 'scroll',
        ...(styles && styles.valueContainer && styles.valueContainer(s)),
      }),
    };

    return (
      <Select
        styles={selectStyles}
        inputId={id}
        name={name}
        options={showOptions ? options : selectedOptions}
        isMulti={isMulti}
        isClearable={isClearable}
        value={selectedOptions}
        onChange={this.onChange}
        onBlur={this.handleBlur}
        onInputChange={minSearchLength ? this.onInputChange : undefined}
        noOptionsMessage={this.noOptionsMessage}
        placeholder={showOptions ? 'Выберите...' : 'Начните вводить...'}
        components={components}
      />
    );
  }

  onSortEnd: SortEndHandler = ({ oldIndex, newIndex }) => {
    const { onChange } = this.props;
    const { selectedOptions } = this.state;
    function arrayMove<T>(array: readonly T[], from: number, to: number) {
      const slicedArray = array.slice();
      slicedArray.splice(
        to < 0 ? array.length + to : to,
        0,
        slicedArray.splice(from, 1)[0],
      );
      return slicedArray;
    }
    const newValue = arrayMove(selectedOptions, oldIndex, newIndex);
    this.setState({ selectedOptions: newValue });
    onChange(newValue.map(option => option.value));
  };

  renderSortable() {
    const {
      id,
      name,
      isMulti,
      isClearable,
      components,
    } = this.props;

    const SortableStyles = {
      multiValueLabel: (s) => ({
        ...s,
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
      }),
      multiValue: (s) => ({
        ...s,
        flex: '1 1 100%',
        justifyContent: 'space-between',
      }),
    };
    const { selectedOptions } = this.state;

    return (
      <SortableSelect
        useDragHandle
        styles={SortableStyles}
        axis="xy"
        onSortEnd={this.onSortEnd}
        distance={4}
        getHelperDimensions={({ node }) => node.getBoundingClientRect()}
        components={{
          MultiValue: SortableMultiValue,
          MultiValueLabel: SortableMultiValueLabel,
          Option: components.Option,
        }}
        closeMenuOnSelect={false}
        inputId={id}
        name={name}
        isClearable={isClearable}
        loadOptions={this.loadOptions}
        isMulti={isMulti}
        value={selectedOptions}
        onChange={this.onChange}
        onBlur={this.handleBlur}
        placeholder={'Начните вводить (минимально 3 символа)...'}
      />
    );
  }
  render() {
    const {
      async,
      creatable,
      sortable,
    } = this.props;

    if (sortable) {
      return this.renderSortable();
    }
    if (creatable) {
      return this.renderCreatable();
    }

    if (async) {
      return this.renderAsync();
    }

    return this.renderSelect();
  }
}

export default ReactSelect;
