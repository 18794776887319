import React from 'react';
import { FormikProps } from 'formik';
import ItemForm from 'features/items/lib/Form';
import getArticleQuery from 'features/articles/queries/getArticleQuery.gql';
import { IFormValues } from 'features/articles/types';
import { IItem } from 'features/types';
import BaseForm from './Form';
import field from 'lib/field';

interface IFormProps {
  data?: IItem;
}

class Form extends React.PureComponent<IFormProps> {
  resolveMutationData = (values: any) => {
    return values;
  }

  renderForm = (props: FormikProps<IFormValues>) => (<BaseForm {...props} item={this.props.data} />);

  validationSchema = (shape: any) => ({
    ...shape,
  });

  resolveInitialValues = (values: any) => {
    const { data } = this.props;
    return {
      ...values,
      article: {
        duration: field(data, 'article.duration', 0),
      },
      content: [{
        id: field(data, 'content[0].id', ''),
        article: {
          id: field(data, 'content[0].article.id', undefined),
          text: field(data, 'content[0].article.text', ''),
        },
      }],
    };
  };

  render() {
    const {
      data,
    } = this.props;

    return (
      <ItemForm
        type="article"
        data={data}
        getItemQuery={getArticleQuery}
        resolveValidationSchema={this.validationSchema}
        resolveInitialValues={this.resolveInitialValues}
        resolveMutationData={this.resolveMutationData}
        renderForm={this.renderForm}
      />
    );
  }
}

export default Form;
