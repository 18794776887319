import React from 'react';
import {
  graphql,
  OptionProps,
} from 'react-apollo';
import {
  client,
  gql,
} from 'features/graphql';
import {
  IListQueryVariables,
  ISelectOption,
  ISharedSelectProps,
} from 'features/types';
import {
  IItem,
  IItemQueryResponse,
} from 'features/items/types';
import ReactSelectWithId from 'features/ui/ReactSelect/ReactSelectWithId';

interface IItemsSelectProps extends ISharedSelectProps {
  data: IItem[];
  itemId?: string;
}

interface IItemsSelectState {
  options: ISelectOption[];
}

class ItemsSelect extends React.PureComponent<IItemsSelectProps, IItemsSelectState> {
  static getDerivedStateFromProps(props: IItemsSelectProps) {
    return {
      options: ItemsSelect.mapItemsToOptions(props.data),
    };
  }

  static mapItemsToOptions = (data: IItem[]) => data.map((item: IItem) => {
    let subtype = '';
    if (item.book && item.book.type === 'summary') { subtype = 'summary'; }
    if (item.audiobook && item.audiobook.type === 'summary') { subtype = 'audiosummary'; }
    if (item.video && item.video.type === 'summary') { subtype = 'videosummary'; }

    return {
      value: +item.id,
      label: item.name,
      type: subtype.length ? subtype : item.type,
    };
  })

  state = {
    options: [],
  };

  loadOptions = (value: string) => {
    const { itemId } = this.props;
    return client.query<IItemQueryResponse>({
      query: QUERY,
      variables: {
        pageNum: 1,
        perPage: 100,
        searchByFields: {
          name: value,
        },
        filterByFields: {
          id: {
            conditions_or: [{
              operator: '<',
              value: itemId,
            },
            {
              operator: '>',
              value: itemId,
            }],
          },
        },
      },
    }).then((res) => {
      if (res.data && res.data.ItemQuery) {
        return ItemsSelect.mapItemsToOptions(res.data.ItemQuery.items);
      }
      return [];
    });
  };

  noOptionsMessage = () => 'Ничего не найдено';

  render() {
    const {
      id,
      isMulti,
      name,
      value,
      onBlur,
      onChange,
      onPaste,
      isClearable,
      sortable,
    } = this.props;

    const {
      options,
    } = this.state;

    return (
      <ReactSelectWithId
        id={id}
        name={name}
        onPaste={onPaste}
        options={options}
        isClearable={isClearable}
        sortable={sortable}
        isMulti={isMulti === undefined ? true : isMulti}
        async
        loadOptions={this.loadOptions}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        noOptionsMessage={this.noOptionsMessage}
        minSearchLength={1}
      />
    );
  }
}

const QUERY = gql`
  query getItemForSelect(
    $id: [ID]
    $pageNum: Int
    $perPage: Int
    $searchByFields: ItemSearchByFields
    $filterByFields: ItemFilterByFields
  ) {
    ItemQuery(
      id: $id,
      page: $pageNum,
      perPage: $perPage,
      searchByFields: $searchByFields,
      filterByFields: $filterByFields,
    ) {
      items {
        id
        name
        type
        book {
          type
        }
        video {
          type
        }
        audiobook {
          type
        }
      }
    }
  }
`;

const mapResultsToProps = (props: OptionProps<ISharedSelectProps, IItemQueryResponse, IListQueryVariables>) => {
  const { data, ownProps } = props;
  return {
    data: data && data.ItemQuery ? data.ItemQuery.items : [],
    ...ownProps,
  };
};

export default graphql<ISharedSelectProps, IItemQueryResponse, IListQueryVariables, IItemsSelectProps>(QUERY, {
  props: mapResultsToProps,
  options: (ownProps) => {
    return {
      variables: {
        id: ownProps.value || undefined,
        pageNum: 1,
        perPage: 100,
        searchByFields: null,
        filterByFields: {
          id: {
            conditions_or: [{
              operator: '<',
              value: ownProps.itemId,
            },
            {
              operator: '>',
              value: ownProps.itemId,
            }],
          },
        },
      },
    };
  },
})(ItemsSelect);
